import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import styled, { css } from 'styled-components'

import { LocationSelect } from 'components/LocationSelect'

function dangerBorder(selector?: string) {
  return css`
    & ${selector} {
      border-color: ${getToken('color-danger')};
    }
  `
}

export const StyledLocationSelect = styled(LocationSelect)<{
  $hasError?: boolean
}>`
  display: flex;
  flex: 1;

  [role='presentation'] {
    max-width: 100%;
  }

  ${({ $hasError }) =>
    $hasError &&
    dangerBorder('[role="presentation"]:nth-of-type(1):not(.is-focused)')}
`
