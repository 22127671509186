import type { Prediction } from '@loadsmart/react-location-select-legacy'

import { CA_STATES, US_STATES } from 'constants/states'

const US_COUNTRY = { value: 'US', label: '🇺🇸 United States of America' }
const CA_COUNTRY = { value: 'CA', label: '🇨🇦 Canada' }
export const countries = [US_COUNTRY, CA_COUNTRY]

export const createFacilityAddress = (address?: Address): Address => {
  return {
    address: address?.address ?? '',
    company_name: address?.company_name ?? '',
    city: address?.city ?? '',
    state: address?.state ?? '',
    zipcode: address?.zipcode ?? '',
    lat: address?.lat ?? '',
    lng: address?.lng ?? '',
    country: address?.country ?? '',
  }
}

export function getPredictionsWithStreetNumber(
  prediction: Prediction
): boolean {
  return ['street_address', 'premise', 'establishment'].some((value) =>
    prediction.types.includes(value)
  )
}

// Gmaps returns the country as 2 letters string
// but magazinno returns the country as 3 letters string
export function getStates(country?: { value: string } | string) {
  if (!country) {
    return US_STATES
  }

  if (typeof country === 'string') {
    return ['US', 'USA'].includes(country) ? US_STATES : CA_STATES
  }

  return ['US', 'USA'].includes(country?.value) ? US_STATES : CA_STATES
}

export function getSelectedCountry(selectedCountry?: string) {
  if (!selectedCountry) {
    return null
  }

  if (['US', 'USA'].includes(selectedCountry)) {
    return US_COUNTRY
  }

  return CA_COUNTRY
}

export function getSelectedState(selectedState?: string) {
  if (!selectedState) {
    return null
  }

  return (
    [...US_STATES, ...CA_STATES].find(
      (state) => state?.value === selectedState
    ) ?? null
  )
}

export function getDrawerTitle(facilityUUID: string) {
  return facilityUUID ? 'Edit facility' : 'Add new facility'
}

export function formatActionLabel(facilityUUID: string) {
  return facilityUUID ? 'Save facility' : 'Create facility'
}
